import request from "@/router/axios";
import apiUrl from "./apiUrl";

export const APIURL = apiUrl;

export const getList = (params) => {
  return request({
    url: apiUrl.list,
    method: "get",
    params: params,
  });
};

export const formalPage = (params) => {
  return request({
    url: apiUrl.formalPage,
    method: "get",
    params: params,
  });
};

export const exportFormalContract = (params) => {
  return request({
    url: apiUrl.exportFormalContract,
    method: "get",
    params: params,
  });
};

export const countDepositAmount = (data) => {
  return request({
    url: apiUrl.countDepositAmount,
    method: "post",
    params: data,
  });
};

export const save = (data) => {
  return request({
    url: apiUrl.save,
    method: "post",
    data: data,
  });
};

export const update = (data) => {
  return request({
    url: apiUrl.update,
    method: "post",
    data: data,
  });
};

export const updateAttachments = (data) => {
  return request({
    url: apiUrl.updateAttachments,
    method: "post",
    data: data,
  });
};

export const supplementarySave = (data) => {
  return request({
    url: apiUrl.supplementarySave,
    method: "post",
    data: data,
  });
};

export const supplementaryUpdate = (data) => {
  return request({
    url: apiUrl.supplementaryUpdate,
    method: "post",
    data: data,
  });
};

export const remove = (data) => {
  return request({
    url: apiUrl.remove,
    method: "post",
    data: data,
  });
};

export const calculate = (data) => {
  return request({
    url: apiUrl.calculate,
    method: "post",
    data: data,
  });
};

export const supplementaryCalculate = (data) => {
  return request({
    url: apiUrl.supplementaryCalculate,
    method: "post",
    data: data,
  });
};

export const detail = (params) => {
  return request({
    url: apiUrl.detail,
    method: "get",
    params: params,
  });
};

export const getSupplementaryDetail = (params) => {
  return request({
    url: apiUrl.supplementaryDetail,
    method: "get",
    params: params,
  });
};

export const queryCollectionPlan = (params) => {
  return request({
    url: apiUrl.queryCollectionPlan,
    method: "get",
    params: params,
  });
};
export const querySupplementaryCollectionPlan = (params) => {
  return request({
    url: apiUrl.supplementaryCollectionPlan,
    method: "get",
    params: params,
  });
};

export const cancelRent = (data) => {
  return request({
    url: apiUrl.cancelRent,
    method: "post",
    data: data,
  });
};

export const cancellation = (data) => {
  return request({
    url: apiUrl.cancellation,
    method: "post",
    data: data,
  });
};

export const close = (params) => {
  return request({
    url: apiUrl.close,
    method: "get",
    params: params,
  });
};

//企业查看我的合同
export const getListByCompany = (data) => {
  return request({
    url: "/api/park-contract/contract/page-by-company",
    method: "get",
    params: data,
  });
};

//企业查看我的合同
export const queryContractAttachment = (id) => {
  return request({
    url: "/api/park-contract/contract/queryContractAttachment?id=" + id,
    method: "get",
  });
};
//企业入驻
export const companycheckin = (data) => {
  return request({
    url: "/api/park-company/companycheckin/checkin",
    method: "post",
    data: data,
  });
};

//重新下发门禁权限
export const checkInCallback = (data) => {
  return request({
    url: "/api/park-company/companycheckin/checkInCallback",
    method: "post",
    data: data,
  });
};

//企业退出
export const checkout = (data) => {
  return request({
    url: "/api/park-company/companycheckin/checkout",
    method: "post",
    data: data,
  });
};

//企业新增
export const companySave = (data) => {
  return request({
    url: "/api/park-company/company/save",
    method: "post",
    data: data,
  });
};


// 删除主合同
export const deleteContract = (id) => {
  return request({
    url: apiUrl.delete + "?id=" + id,
    method: "post",
  });
};

// 删除补充协议
export const deleteSubContract = (id) => {
  return request({
    url: apiUrl.subContract.delete + "?id=" + id ,
    method: "post",
    // data: data,
  });
};
