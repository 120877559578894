<template>
  <div>
    <div v-if="info.status !== 2">
      <el-alert v-if="info.status === 1" title="待缴纳" type="warning" show-icon :closable="false"> 当前账单费用还未缴纳，待缴纳金额为：￥{{ (info.paidAmount || 0) | numConvertToThousandths }}元 </el-alert>
      <el-alert v-if="info.status === 4" title="部分缴纳" type="warning" show-icon :closable="false"> 当前账单费用还有部分未缴纳，待缴纳金额为：￥{{ (info.paidAmount || 0) | numConvertToThousandths }}元 </el-alert>
      <el-alert v-if="info.status === 3" title="账单逾期" type="error" show-icon :closable="false"> 当前账单已逾期，逾期待缴纳金额为：￥{{ (info.paidAmount || 0) | numConvertToThousandths }}元 </el-alert>
    </div>
    <el-row type="flex">
      <el-col class="pad-20">
        <el-descriptions title="" :column="3" direction="vertical" :colon="false" :labelStyle="labelStyle">
          <el-descriptions-item label="账单编号">{{ info.receiptNo }}</el-descriptions-item>
          <el-descriptions-item label="账单对象">{{ info.subject }}</el-descriptions-item>
          <el-descriptions-item label="账单地址">{{ info.subjectAddress }}</el-descriptions-item>
          <el-descriptions-item label="应收金额">￥{{ (info.amountReceivable || 0) | numConvertToThousandths }}元</el-descriptions-item>
          <el-descriptions-item label="补贴金额">￥{{ (info.amountReduction || 0) | numConvertToThousandths }}元</el-descriptions-item>
          <el-descriptions-item label="实收金额">￥{{ (info.amountReceived || 0) | numConvertToThousandths }}元</el-descriptions-item>
          <el-descriptions-item label="待缴金额">￥{{ (info.paidAmount || 0) | numConvertToThousandths }}元</el-descriptions-item>
          <el-descriptions-item label="账单周期开始时间">{{ info.periodStartTime }}</el-descriptions-item>
          <el-descriptions-item label="账单周期结束时间">{{ info.periodEndTime }}</el-descriptions-item>
          <el-descriptions-item label="应缴日期">{{ info.receivableDate }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col v-if="(info.status !== 2 || info.processInstanceId) && !isTodo" class="pad-15" style="width: 150px">
        <template v-if="info.status !== 2">
          <div>
            <el-button v-if="!isCompany" size="small" class="dis-b w-100p" type="primary" @click="addPay">缴纳</el-button>
            <el-button v-if="isCompany" size="small" class="dis-b w-100p" type="primary" @click="addPayByCompany">缴纳</el-button>
          </div>
          <div v-if="!isCompany" class="mar-t-10">
            <el-button size="small" class="dis-b w-100p" @click="reminderPay">催缴</el-button>
          </div>
          <div v-if="!isCompany" class="mar-t-10">
            <el-button size="small" class="dis-b w-100p" @click="openPrefSuppDialog">优惠补录</el-button>
          </div>
          <div class="mar-t-10">
            <el-button size="small" class="dis-b w-100p" @click="startFlow">审批凭证</el-button>
          </div>
        </template>
        <div class="mar-t-10">
          <el-button
            v-if="info.processInstanceId"
            size="small"
            class="dis-b w-100p"
            @click="
              () => {
                todoDrawer = true
              }
            "
            >审批结果</el-button
          >
        </div>
      </el-col>
    </el-row>
    <div class="pad-20">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="账单明细" name="list"></el-tab-pane>
        <el-tab-pane label="缴费明细" name="pay"></el-tab-pane>
        <el-tab-pane label="优惠明细" name="reduction"></el-tab-pane>
        <el-tab-pane label="补贴明细" name="subsidy" v-if="userInfo.tenant_id === '000000'"></el-tab-pane>
      </el-tabs>
      <ReceiptDetailList :key="newTime" class="mar-t-40f" v-if="activeTabName === 'list'" :receiptNo="id"></ReceiptDetailList>
      <ReceiptDetailPay :key="newTime" class="mar-t-40f" v-if="activeTabName === 'pay'" :receiptNo="id" :processInstanceId="processInstanceId" :isTodo="isTodo" :receiptStatus="info.status" :approvalStatus="info.approvalStatus || 1" :isCompany="isCompany"></ReceiptDetailPay>
      <ReceiptDetailReduction :key="newTime" class="mar-t-40f" v-if="activeTabName === 'reduction'" :receiptNo="id" :isTodo="isTodo"></ReceiptDetailReduction>
      <ReceiptDetailSubsidy :key="newTime" v-if="activeTabName === 'subsidy'" :receiptNo="id"></ReceiptDetailSubsidy>
    </div>
    <el-dialog title="缴纳费用" :visible.sync="dialogVisible" width="40%" append-to-body>
      <el-form v-if="dialogVisible" ref="form" :model="form" size="small" label-width="120px">
        <el-form-item :rules="[{ required: true, message: '请选择缴纳方式', trigger: 'blur' }]" label="缴纳方式" prop="paymentType">
          <el-radio-group v-model="form.paymentType" @change="paymentTypeChange">
            <el-radio :label="4">现金</el-radio>
            <el-radio :label="5">线下银行转账</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.paymentType === 5">
          <el-form-item label="银行" prop="accountBank">
            <el-select class="dis-b" v-model="form.accountBank" filterable>
              <el-option v-for="item of dictData.accountBank" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '请输入缴纳金额', trigger: 'blur' }]" label="缴纳金额" prop="money">
            <el-input v-model="form.money" type="number" placeholder="请输入缴纳金额"></el-input>
          </el-form-item>
          <el-form-item label="缴费开户行" prop="depositBank">
            <el-input v-model="form.depositBank" type="text" placeholder="请输入缴费开户行"></el-input>
          </el-form-item>
          <el-form-item label="缴费账号" prop="account">
            <el-input v-model="form.account" type="text" placeholder="请输入缴费账号"></el-input>
          </el-form-item>
          <el-form-item label="缴费时间" prop="payTime">
            <el-date-picker class="dis-b w-100p" v-model="form.payTime" type="datetime" placeholder="请选择缴费时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item label="凭证编号" prop="serialNumber">
            <el-input v-model="form.serialNumber" type="text" placeholder="请输入凭证编号"></el-input>
          </el-form-item>
          <el-form-item label="凭证附件" prop="attachmentId">
            <form-file v-model="form.attachmentId" :multiple="false"></form-file>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input v-model="form.note" type="textarea" placeholder="请输入备注"></el-input>
          </el-form-item>
        </template>
        <el-form-item v-if="form.paymentType === 4" :rules="[{ required: true, message: '请输入缴纳金额', trigger: 'blur' }]" label="缴纳金额" prop="money">
          <el-input v-model.number="form.money" type="number" placeholder="请输入金额"></el-input>
        </el-form-item>
        <el-form-item class="mar-t-40 text-right">
          <el-button size="small" @click="dialogVisible = false" :loading="saveLoading">取消</el-button>
          <el-button size="small" @click="savePay" :loading="saveLoading" type="primary">确认支付</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="缴纳费用" :visible.sync="dialogVisible1" width="640px" append-to-body>
      <el-alert :closable="false" show-icon class="mar-b-10" title="温馨提示" type="warning" description="如果缴款方式选择【B2B企业支付】未找到相关银行，请选择【线下银行转账】并填写相关信息"> </el-alert>
      <el-form ref="jfForm" v-if="dialogVisible1" :model="jfForm" size="small" label-width="120px">
        <el-form-item :rules="[{ required: true, message: '请选择缴纳方式', trigger: 'blur' }]" label="缴纳方式" prop="paymentType">
          <el-radio-group v-model="jfForm.paymentType" @change="paymentTypeChange1">
            <el-radio :label="6">B2B企业支付</el-radio>
            <el-radio :label="5">线下银行转账</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template v-if="showJfFrom && dialogVisible1 && jfForm.paymentType == 6">
        <el-form ref="jfFormByB2B" :model="form1" size="small" label-width="120px">
          <!-- <el-form-item :rules="[
            { required: true, message: '请选择缴纳方式', trigger: 'blur' }
            ]" label="缴纳方式" prop="payType">
                        <el-radio-group v-model="form2.payType">
                            <el-radio :label="6">B2B企业支付</el-radio>
                            <el-radio :label="5">线下银行转账</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
          <el-form-item label-width="0px">
            <el-table :data="form1TableData" border @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="费用科目" prop="feeName"></el-table-column>
              <el-table-column label="应缴费用" width="120" prop="amountReceivable"></el-table-column>
              <el-table-column label="实缴费用" width="120" prop="amountReceived"></el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label-width="0px">
            <div class="pull-left">
              选中<span class="mar-lr-3 text-blue">{{ form1.ids.length }}</span
              >笔费用，共<span class="mar-lr-3 text-blue">￥{{ form1.amount }}</span
              >元
            </div>
            <div class="pull-right">
              <el-button size="small" @click="dialogVisible1 = false" :loading="saveLoading">取消</el-button>
              <el-button size="small" @click="saveB2BPay" :loading="saveLoading" type="primary">确认支付</el-button>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-if="showJfFrom && dialogVisible1 && jfForm.paymentType == 5">
        <el-form ref="jfFormByBank" :model="form2" size="small" label-width="120px">
          <!-- <el-form-item :rules="[
            { required: true, message: '请选择缴纳方式', trigger: 'blur' }
            ]" label="缴纳方式" prop="payType">
                        <el-radio-group v-model="form1.payType">
                            <el-radio :label="6">B2B企业支付</el-radio>
                            <el-radio :label="5">线下银行转账</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
          <template v-if="bakInfo">
            <el-form-item label="收款银行">
              {{ bakInfo.account_bank }}
            </el-form-item>
            <el-form-item label="收款开户行">
              {{ bakInfo.deposit_bank }}
            </el-form-item>
            <el-form-item label="收款账号">
              <span class="fts-18 fts-b">{{ bakInfo.collection_account | formatBankNum }}</span>
            </el-form-item>
          </template>
          <el-form-item label="银行" prop="accountBank" :rules="[{ required: true, message: '请选择缴费银行', trigger: 'blur' }]">
            <el-select class="dis-b" v-model="form2.accountBank" filterable>
              <el-option v-for="item of dictData.accountBank" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '请输入缴纳金额', trigger: 'blur' }]" label="缴纳金额" prop="money">
            <el-input v-model="form2.money" type="number" placeholder="请输入缴纳金额"></el-input>
          </el-form-item>
          <el-form-item label="缴费开户行" prop="depositBank" :rules="[{ required: true, message: '请输入缴费开户行', trigger: 'blur' }]">
            <el-input v-model="form2.depositBank" type="text" placeholder="请输入缴费开户行"></el-input>
          </el-form-item>
          <el-form-item label="缴费账号" prop="account" :rules="[{ required: true, message: '请输入缴费账号', trigger: 'blur' }]">
            <el-input v-model="form2.account" type="text" placeholder="请输入缴费账号"></el-input>
          </el-form-item>
          <el-form-item label="缴费时间" prop="payTime" :rules="[{ required: true, message: '请选择缴费时间', trigger: 'blur' }]">
            <el-date-picker class="dis-b w-100p" v-model="form2.payTime" type="datetime" placeholder="请选择缴费时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="凭证编号" prop="serialNumber">
                        <el-input v-model="form2.serialNumber" type="text" placeholder="请输入凭证编号"></el-input>
                    </el-form-item> -->
          <el-form-item label="凭证附件" prop="attachmentId" :rules="[{ required: true, message: '请上传凭证附件', trigger: 'blur' }]">
            <form-file v-model="form2.attachmentId" :multiple="false"></form-file>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input v-model="form2.note" type="textarea" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item class="mar-t-40 text-right">
            <el-button size="small" @click="dialogVisible1 = false" :loading="saveLoading">取消</el-button>
            <el-button size="small" @click="savePay1" :loading="saveLoading" type="primary">确认支付</el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
    <el-drawer :visible.sync="todoDrawer" :size="400" :withHeader="false" append-to-body>
      <el-container v-if="todoDrawer" class="h-100p">
        <el-header class="bor-b" height="50px">
          <el-page-header class="lh-50" @back="todoDrawer = false" content="审批记录"></el-page-header>
        </el-header>
        <el-main class="pad-15">
          <TodoTask :id="info.processInstanceId"></TodoTask>
        </el-main>
      </el-container>
    </el-drawer>
    <el-dialog title="优惠补录" :visible.sync="prefSuppDialogVisible" width="40%" append-to-body>
      <el-form v-if="prefSuppDialogVisible" ref="prefSuppForm" :model="prefSuppForm" size="small" label-width="120px">
        <el-form-item :rules="[{ required: true, message: '请选择优惠政策', trigger: 'blur' }]" label="优惠政策" prop="reductionPolicyId">
          <el-select v-model="prefSuppForm.reductionPolicyId" placeholder="请选择优惠政策">
            <el-option v-for="item in reducitionList" :key="item.reduction_policy_id" :label="item.reduction_policy_title" :value="item.reduction_policy_id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-form-item :rules="[{ required: true, message: '请输入优惠金额', trigger: 'blur' }]" label="优惠金额" prop="reductionAmount">
            <el-input v-model.number="prefSuppForm.reductionAmount" type="number" placeholder="请输入优惠金额"></el-input>
          </el-form-item>
          <div class="pull-right">
            <el-button size="small" @click="prefSuppDialogVisible = false">取消</el-button>
            <el-button size="small" @click="savePrefSupp" type="primary">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex"
import mathjs from "@/util/math.js"
import { getDetail, bankCheck, reminderPay, addPay, b2bPay, getNeedPayItem, calculatePay, additionalAmount, checkApproval } from "@/api/finance/index.js"
import { getMetaCommonList } from "@/api/busiMode/metaCommon"
import { getDictionary } from "@/api/system/dictbiz.js"
import { startFlow } from "@/api/flow/flow.js"

import TodoTask from "@/views/flow/todo-task.vue"
import ReceiptDetailList from "./receipt-detail-list.vue"
import ReceiptDetailPay from "./receipt-detail-pay.vue"
import ReceiptDetailReduction from "./receipt-detail-reduction.vue"
import ReceiptDetailSubsidy from "./receipt-detail-subsidy.vue"

export default {
  components: { ReceiptDetailList, ReceiptDetailPay, ReceiptDetailReduction, ReceiptDetailSubsidy, TodoTask },
  data() {
    return {
      bankCheckLoading: false,
      newTime: Date.now(),
      saveLoading: false,
      activeTabName: this.activeTab ? this.activeTab : this.isTodo ? "pay" : "list",
      loading: false,
      dialogVisible: false,
      dialogVisible1: false,
      info: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      todoDrawer: false,
      form: {
        paymentType: 4,
        money: null,
        serialNumber: "",
        accountBank: "",
        depositBank: "",
        account: "",
        attachmentId: "",
        payTime: "",
        note: "",
      },
      form2: {
        paymentType: 5,
        money: null,
        serialNumber: "",
        accountBank: "",
        depositBank: "",
        account: "",
        attachmentId: "",
        payTime: "",
        note: "",
      },
      jfForm: {
        paymentType: 6,
      },
      showJfFrom: false,
      form1: {},
      form1TableData: [],
      dictData: {
        accountBank: [],
      },
      prefSuppDialogVisible: false,
      prefSuppForm: {},
      reducitionList: [],
      bakInfo: null,
    }
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
    processInstanceId: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 获得用户信息来区分角色
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    isCompany() {
      return this.userInfo.tenant_id !== "000000" && this.userInfo.companyId ? true : false
    },
  },
  mounted() {
    getMetaCommonList("wisdom_park.wp_collection_account", {
      current: 1,
      size: 10000,
      wheres: [{ field: "deposit_bank", value: "建设银行", op: "LIKE" }],
    }).then((res) => {
      if (res.data.data.records.length) {
        this.bakInfo = res.data.data.records[0]
      } else {
        this.bakInfo = null
      }
    })
    this.getDetail()
    this.getDictionary()
  },
  methods: {
    startFlow() {
      checkApproval({
        receiptNo: this.id,
      }).then((res) => {
        if (res.data && res.data.code === 200) {
          this.$confirm("确定提交审批?", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let businessType = this.$PCode.BIZ.RECEIPT
              let actionCode = this.$PCode.ACTIONS.SUBMIT
              return startFlow(this.id, businessType, actionCode, {
                title: this.info.subject,
              })
            })
            .then(() => {
              this.getDetail()
              this.$message({
                type: "success",
                message: "操作成功!",
              })
            })
        }
      })
    },
    paymentTypeChange1() {
      this.showJfFrom = false
      this.$nextTick(() => {
        this.showJfFrom = true
      })
    },
    paymentTypeChange() {
      if (this.form.paymentType == 5) {
        this.form.money = null
      }
      if (this.form.paymentType == 4) {
        this.form.money = Number(this.info.paidAmount)
      }
    },
    getDictionary() {
      ;[{ prop: "accountBank", model: "BankType" }].forEach((item) => {
        getDictionary({
          code: item.model,
        }).then((res) => {
          this.dictData[item.prop] = res.data.data || []
        })
      })
    },
    getDetail() {
      if (this.id) {
        getDetail({
          receiptNo: this.id,
        }).then((res) => {
          this.info = res.data.data || {}
        })
      }
    },
    getYSAmount() {
      return mathjs.subtract(this.info.amountReceivable || 0, this.info.amountReceived || 0)
    },
    addPay() {
      this.form = {
        paymentType: 4,
        money: Number(this.info.paidAmount),
        serialNumber: "",
        accountBank: "",
        depositBank: "",
        account: "",
        attachmentId: "",
        payTime: "",
      }
      this.dialogVisible = true
    },
    handleSelectionChange(rows) {
      let ids = []
      // let amount = mathjs.chain(0);
      rows.forEach((item) => {
        ids.push(item.id)
        // amount.add(item.paid);
      })
      this.form1.amount = "-"
      calculatePay({
        receiptNo: this.id,
        ids: ids,
      }).then((res) => {
        this.form1.amount = res.data.data.needPayAmount
      })
      this.form1.ids = ids
    },
    addPayByCompany() {
      this.jfForm = {
        paymentType: 6,
      }
      this.showJfFrom = true
      this.form1 = {
        payType: 6,
        ids: [],
        amount: 0,
      }
      this.form2 = {
        paymentType: 5,
        money: Number(this.info.paidAmount),
        serialNumber: "",
        accountBank: "",
        depositBank: "",
        account: "",
        attachmentId: "",
        payTime: "",
        note: "",
      }
      this.form1TableData = []
      this.dialogVisible1 = true
      getNeedPayItem({
        receiptNo: this.id,
      }).then((res) => {
        this.form1TableData = res.data.data
      })
    },
    bankCheck() {
      if (this.form.accountBank && this.form.serialNumber) {
        this.bankCheckLoading = true
        bankCheck({
          accountBank: this.form.accountBank,
          serialNumber: this.form.serialNumber,
        })
          .then((res) => {
            let data = res.data.data
            this.form.money = data.money
            this.bankCheckLoading = false
          })
          .catch((e) => {
            this.bankCheckLoading = false
          })
      } else {
        this.$message({
          type: "error",
          message: "银行和回执单号不能为空",
        })
      }
    },
    savePay1() {
      this.$refs.jfFormByBank.validate((valid) => {
        if (valid) {
          this.savePayForBank(this.form2)
        }
      })
    },
    savePayForBank(form) {
      form = form || this.form
      // && this.form.accountBank && this.form.attachmentId
      if (form.money) {
        if (!this.id) {
          this.$message({
            type: "error",
            message: "没有获取到账单编号，请重试",
          })
          return false
        }
        this.saveLoading = true
        addPay({
          receiptNo: this.id,
          ...form,
        })
          .then((res) => {
            this.saveLoading = false
            this.dialogVisible = false
            this.dialogVisible1 = false
            this.newTime = Date.now()
            this.getDetail()
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.$emit("refreshTable")
          })
          .catch((e) => {
            this.saveLoading = false
          })
      }
    },
    savePayForMoney() {
      if (this.form.money) {
        if (!this.id) {
          this.$message({
            type: "error",
            message: "没有获取到账单编号，请重试",
          })
          return false
        }
        this.saveLoading = true
        addPay({
          receiptNo: this.id,
          ...this.form,
        })
          .then((res) => {
            this.saveLoading = false
            this.dialogVisible = false
            this.newTime = Date.now()
            this.getDetail()
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.$emit("refreshTable")
          })
          .catch((e) => {
            this.saveLoading = false
          })
      }
    },
    savePayForB2B() {
      if (!this.id) {
        this.$message({
          type: "error",
          message: "没有获取到账单编号，请重试",
        })
        return false
      }
      this.saveLoading = true
      b2bPay({
        receiptNo: this.id,
        ...this.form1,
      })
        .then((res) => {
          this.saveLoading = false
          this.dialogVisible1 = false
          window.open(res.data.data)
          // this.newTime = Date.now();
          // this.getDetail();
          // this.$message({
          //   type: "success",
          //   message: "操作成功!"
          // });
          // this.$emit('refreshTable');
        })
        .catch((e) => {
          this.saveLoading = false
        })
    },
    saveB2BPay() {
      if (!this.form1.ids.length) {
        this.$message({
          type: "error",
          message: "您没选择缴费账单",
        })
        return
      }
      this.savePayForB2B()
    },
    savePay() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.paymentType == 5) {
            this.savePayForBank()
          }
          if (this.form.paymentType == 4) {
            this.savePayForMoney()
          }
          if (this.form.payType == 6) {
            this.savePayForB2B()
          }
        } else {
          return false
        }
      })
    },
    reminderPay() {
      if (this.id) {
        this.$confirm("确定要催缴该笔账单吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          return reminderPay({
            receiptNo: this.id,
          }).then(() => {
            this.$message({
              type: "success",
              message: "催缴成功!",
            })
          })
        })
      } else {
        this.$message({
          type: "error",
          message: "没有获取到账单编号，请重试",
        })
      }
    },
    openPrefSuppDialog() {
      this.getReducitions()
      this.prefSuppDialogVisible = true
    },
    getReducitions() {
      if (this.info.subjectId) {
        getMetaCommonList("wisdom_park.wp_reduction_policy_company", {
          current: 1,
          size: 100,
          wheres: [{ field: "company_id", value: this.info.subjectId }],
        }).then((res) => {
          if (res.data && res.data.code === 200) {
            this.reducitionList = res.data.data.records
          }
        })
      } else {
        this.reducitionList = []
      }
    },
    savePrefSupp() {
      this.$refs.prefSuppForm.validate((valid) => {
        if (valid) {
          let reductionPolicyName = ""
          this.reducitionList.forEach((e) => {
            if (e.reduction_policy_id == this.prefSuppForm.reductionPolicyId) {
              reductionPolicyName = e.reduction_policy_title
            }
          })
          let obj = {
            reductionPolicyId: this.prefSuppForm.reductionPolicyId, //优惠政策ID
            reductionPolicyName: reductionPolicyName, //优化政策名称
            receiptNo: this.info.receiptNo, //账单编号
            reductionAmount: this.prefSuppForm.reductionAmount, //优惠金额
          }

          additionalAmount(obj).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功!",
              })
              this.getDetail()
              this.prefSuppDialogVisible = false
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              })
            }
          })
        }
      })
    },
  },
}
</script>
