/*
 * @Description: 
 * @Author: Niklaus
 * @Date: 2024-04-23 10:04:57
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-03-31 18:21:43
 */
const prefix = '/api/park-asset/'

export default {
  assetDetailImport: prefix + 'assetdetail/import',
  updateImport: prefix + 'assetdetail/updateImport',
  repairFinsh: prefix + 'assetrepair/repairFinsh',
  assetdetail: prefix + 'assetdetail/detail/',
  scrapApprovalFinsh: prefix + 'assetscrap/approvalFinsh',
  getList: prefix + 'assetdetail/list',
  queryAssetDetailByPage: prefix + 'assetdetail/queryAssetDetailByPage',
  exportChild: prefix + 'assetdetail/getChildList',
  jfAssetList: prefix + '/assetdetailfeng/page',
  wdzAssetList: prefix + 'assetdetailwdz/page'
}
