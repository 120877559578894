/*
 * @Description: 
 * @Author: Niklaus
 * @Date: 2024-04-23 10:04:57
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-04-01 09:23:41
 */
import request from '@/router/axios';
import APIS from './apiUrl';

export const importAssetsDetail = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: APIS.assetDetailImport,
    method: 'post',
    data: formData
  })
}
export const updateImport = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return request({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: APIS.updateImport,
    method: 'post',
    data: formData
  })
}
export const repairFinsh = (data) => {
  return request({
    url: APIS.repairFinsh,
    method: 'post',
    data: data
  })
}

export const getAssetDetailInfo = (id) => {
  return request({
    url: APIS.assetdetail + id,
    method: 'post',
    params: {}
  })
}

export const j2printer = (data) => {
  return request({
    url: 'http://localhost:19999/j2printer/run',
    method: 'post',
    data: data
  })
}

export const scrapApprovalFinsh = (data) => {
  return request({
    url: APIS.scrapApprovalFinsh,
    method: 'post',
    data: data
  })
}

export const getList = (data) => {
  return request({
    url: APIS.getList,
    method: 'post',
    data: data
  })
}

export const queryAssetDetailByPage = (data) => {
  return request({
    url: APIS.queryAssetDetailByPage,
    method: 'post',
    data: data
  })
}

export const exportChild = (params) => {
  return request({
    url: APIS.exportChild,
    method: 'get',
    params: params
  })
}


export const jfAssetList = (data) => {
  return request({
    url: APIS.jfAssetList,
    method: 'post',
    data: data
  })
}


export const wdzAssetList = (data) => {
  return request({
    url: APIS.wdzAssetList,
    method:  'post',
    data: data
  })
}