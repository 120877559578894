import * as dictService from "@/api/system/dictbiz";
const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;
export const managementOption = {
  searchMenuSpan: 4,
  height: "auto",
  searchMenuPosition: "left",
  dateBtn: false,
  border: true,
  tip: true,
  searchShow: true,
  addBtn: false,
  refreshBtn: true,
  addRowBtn: false,
  columnBtn: true,
  delBtn: false,
  editBtn: false,
  cancelBtn: true,
  rowKey: "",
  showPage: true,
  index: true,
  selection: false,
  showHeader: true,
  expand: false,
  highlightCurrentRow: true,
  stripe: false,
  emptyBtn: false,
  menuWidth: 240,
  reserveSelection: true,
  column: [
    {
      width: "auto",
      overHidden: true,
      align: "right",
      label: "主键id",
      prop: "id",
      hide: true,
    },
    // {
    //   width: 150,
    //   overHidden: true,
    //   label: "所属楼宇",
    //   prop: "building_name",
    //   hide: false,
    //   addDisplay:false,
    //   editDisplay:false,
    //   viewDisplay:false,
    // },

    {
      hide: true,
      width: 150,
      overHidden: true,
      label: "所属楼宇",
      prop: "building_id",
      hide: false,
      search: true,
      type: "select",
      dicData: [],
      searchSpan: 6,
    },
    {
      width: "200",
      overHidden: true,
      align: "left",
      label: "企业名称",
      fixed: true,
      prop: "name",
      search: true,
      hide: false,
      searchSpan: 6,
    },
    {
      width: "150",
      overHidden: true,
      align: "left",
      label: "统一社会信用代码",
      prop: "uniform_social_credit_code",
      search: true,
      hide: false,
      searchSpan: 6,
      searchLabelWidth: 130
    },

    {
      label: "所属行业",
      width: "100",
      prop: "industry",
      dicUrl: dictService.getUrl("industry"),
      props: {
        label: "dictValue",
        value: "dictKey",
      },
      search: true,
      searchSpan: 6,
      type: "select",
    },

    {
      width: "auto",
      overHidden: true,
      align: "left",
      label: "企业法人",
      prop: "legal_person",
      hide: false,
    },
    {
      width: "auto",
      overHidden: true,
      align: "left",
      label: "负责人姓名",
      prop: "principal",
      hide: false,
    },
    {
      width: "100",
      overHidden: true,
      align: "left",
      label: "联系电话",
      prop: "phone_num",
      hide: false,
    },
    {
      width: "auto",
      overHidden: true,
      align: "left",
      label: "联系方式",
      prop: "contact_details",
      hide: true,
    },
    {
      width: "100",
      overHidden: true,
      align: "left",
      label: "企业规模",
      prop: "firm_size",
    },
    {
      width: "90",
      overHidden: true,
      align: "left",
      label: "企业总人数",
      prop: "emp_count",
    },
    {
      width: "100",
      overHidden: true,
      align: "right",
      label: "注册资金(万)",
      prop: "registered_capital",
    },
    // {
    //   width: "80",
    //   overHidden: true,
    //   align: "right",
    //   label: "货币种类",
    //   prop: "registered_capital_currency",
    // },

    {
      width: "100",
      overHidden: true,
      align: "left",
      label: "企业类型",
      prop: "type",
    },
    {
      width: "120",
      overHidden: true,
      align: "left",
      label: "科技型企业类型",
      prop: "technical_type",
    },
    {
      width: "80",
      overHidden: true,
      align: "left",
      label: "上市状态",
      prop: "is_public",
      // dicData: [
      //   {
      //     label: "未上市",
      //     value: 0,
      //   },
      //   {
      //     label: "已上市",
      //     value: 1,
      //   },

      // ],
    },

    // {
    //   width: "100",
    //   overHidden: true,
    //   align: "left",
    //   label: "企业资质",
    //   prop: "qualification",
    // },
    // {
    //   width: "150",
    //   overHidden: true,
    //   align: "left",
    //   label: "企业入驻时间",
    //   prop: "plan_checkin_time",
    // },
    {
      width: "150",
      overHidden: true,
      align: "left",
      label: "合同编号",
      prop: "contract_code",
    },
    {
      width: "150",
      overHidden: true,
      align: "left",
      label: "有无合同",
      prop: "is_contract",
      search: true,
      type: "select",
      hide: true,
      addDisplay: false,
      editDisplay: false,
      viewDisplay: false,
      dicData: [
        {
          label: "有",
          value: 2,
        },
        {
          label: "无",
          value: 1,
        },
      ],
    },
    {
      width: "150",
      overHidden: true,
      align: "left",
      label: "合同生效日期",
      prop: "signed_date",
    },
    {
      width: "auto",
      overHidden: true,
      align: "right",
      label: "排序",
      prop: "sort",
      hide: true,
    },
    {
      width: "auto",
      overHidden: true,
      align: "right",
      label: "创建人id",
      prop: "create_user_id",
      hide: true,
    },
    {
      width: "auto",
      overHidden: true,
      align: "right",
      label: "修改人id",
      prop: "update_user_id",
      hide: true,
    },
    {
      width: "auto",
      overHidden: true,
      align: "left",
      label: "附件集合id",
      prop: "attachment_id",
      hide: true,
    },
    {
      align: "left",
      searchSpan: 6,
      label: "企业状态",
      prop: "status",
      search: true,
      type: "select",
      dicData: [
        {
          label: "无效",
          value: 0,
        },
        {
          label: "已入驻",
          value: 1,
        },
        {
          label: "入驻中",
          value: 2,
        },
        {
          label: "退出中",
          value: 3,
        },
        {
          label: "已退出",
          value: 4,
        },
      ],
    },
  ],
};
