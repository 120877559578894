/*
 * @Description: 
 * @Author: Niklaus
 * @Date: 2024-04-23 10:04:57
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-03-07 16:19:09
 */
const prefix = "/api/park-fee/";

export default {
  list: prefix + "receipt/page",
  rentalList: prefix + 'receipt/paid-page',
  listByCompany: prefix + "receipt/page-by-company",
  total: prefix + "receipt/statistic-fee",
  detail: prefix + "receipt/detail",
  reminderPay: prefix + "receipt/reminder-pay",
  bankCheck: prefix + "receipt/check-bank-receipt",
  addPay: prefix + "receipt/pay",
  b2bPay: prefix + "pay/b2b",
  save: prefix + "receipt/save",
  update: prefix + "receipt/update",
  updateInfo: prefix + "receipt/updateInfo",
  remove: prefix + "receipt/remove",
  preferentialList: prefix + "receipt/preferential-page",
  preferentiaStatisticFee: prefix + "receipt/preferential-statistic-fee",
  needPayItem: prefix + "pay/need-pay-item",
  calculatePay: prefix + "pay/calculate",
  getReductionPolicy: prefix + "receipt/get-reduction-policy",
  getSubsidyPage: prefix + "receipt-subsidy/page",
  saveSubsidy: prefix + "receipt-subsidy/save",
  updateSubsidy: prefix + "receipt-subsidy/update",
  delSubsidy: prefix + "receipt-subsidy/remove",
  receiptExport: prefix + "/receipt/export",
  receiptExportByCompany: prefix + 'receipt/export-by-company',
  additionalAmount: prefix + "/receipt-subsidy/additionalAmount",
  removeReceiptReductionDetail: prefix + "/receipt-subsidy/removeReceiptReductionDetail",
  importReceipt: prefix + 'import/receipt',
  checkApproval: prefix + 'pay/checkApproval',
  dailyAccount: prefix + 'receiptAmountDay/handelCustomUpdate',
  balanceReceipt: prefix + 'pay/handleProcessPC',
  clearPay: prefix + 'pay/handelCustomDelete'
};
