<template>
  <basic-container style="position: relative">
    <avue-crud
      :option="option"
      ref="crud"
      :data="data"
      :page.sync="page"
      :search.sync="search"
      @refresh-change="refreshChange"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope" v-if="!choose">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增企业 </el-button>
        <el-button type="" size="small" icon="el-icon-upload2" @click="handleImport">导入 </el-button>
        <el-button
          class="mar-l-5"
          icon="el-icon-download"
          :loading="downLoading"
          @click="getMetaCommonListExport"
          size="small"
          >导出</el-button
        >
      </template>
      <template slot-scope="scope" slot="menu">
        <template v-if="blacklist">
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            class="none-border"
            @click="removeBlackList(scope.row)"
            >移除
          </el-button>
        </template>
        <template v-if="!blacklist">
          <el-button
            type="text"
            size="small"
            icon="el-icon-view"
            class="none-border"
            @click="openDislog('qyda', scope.row)"
            >企业档案
          </el-button>

          <el-button
            type="text"
            size="small"
            icon="el-icon-suitcase"
            class="none-border"
            @click="openDislog('khxx', scope.row)"
            >开户信息
          </el-button>
          <el-button
            v-if="scope.row.black_list_status != 1"
            type="text"
            size="small"
            icon="el-icon-suitcase"
            class="none-border"
            @click="jrhmd(scope.row)"
            >加入黑名单
          </el-button>
          <el-button
            v-if="scope.row.black_list_status == 1"
            type="text"
            size="small"
            icon="el-icon-suitcase"
            class="none-border"
            @click="removeBlackList(scope.row)"
            >从黑名单移除
          </el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            class="none-border"
            @click="openDislog('edit', scope.row)"
            >编辑
          </el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-c-scale-to-original"
            class="none-border"
            @click="openDislog('bg', scope.row)"
            >变更
          </el-button>
          <el-button
            v-if="scope.row.status == 1"
            type="text"
            size="small"
            icon="el-icon-right"
            class="none-border"
            @click="checkout(scope.row)"
            >企业退出
          </el-button>
          <el-button
            type="text"
            size="small"
            icon="el-icon-tickets"
            class="none-border"
            @click="openDislog('bgjl', scope.row)"
            >变更记录
          </el-button>
          <el-button
            v-if="scope.row.status == 0 || scope.row.status == 4"
            type="text"
            size="small"
            icon="el-icon-delete"
            class="none-border"
            @click="rowDel(scope.row)"
            >删除
          </el-button>
        </template>
      </template>
    </avue-crud>
    <div class="sum_clazz"><small class="mar-r-5">楼宇人员总数:</small>{{ sum }}</div>
    <el-dialog
      :visible.sync="detailDia"
      :close-on-click-modal="false"
      width="60%"
      class="enterDetail"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="header-title">
        <span class="fts-16">{{ title }}</span>
      </div>
      <addEnterprise @close="close" :currentRow="currentRow" :doType="doType"></addEnterprise>
    </el-dialog>
    <el-dialog
      :visible.sync="companyChangeDia"
      :close-on-click-modal="false"
      width="80%"
      class="enterDetail"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="header-title">
        <span class="fts-16">变更记录</span>
      </div>
      <div style="height: calc(80vh - 60px); overflow: hidden; margin-top: -50px">
        <companyChangeList
          v-if="companyChangeDia"
          @close="companyChangeDia = false"
          :currentRow="currentRow"
          :doType="doType"
        ></companyChangeList>
      </div>
    </el-dialog>

    <el-drawer :visible.sync="drawerDia" size="60%" :withHeader="false" append-to-body destroy-on-close>
      <el-header class="bor-b" height="50px">
        <el-page-header class="lh-50" @back="close" :content="title"></el-page-header>
      </el-header>
      <archives @close="close" :currentRow="currentRow"></archives>
    </el-drawer>
    <el-dialog title="企业数据导入" append-to-body :visible.sync="excelBox" width="555px" destroy-on-close>
      <div class="mar-t-20">
        <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              点击下载<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </basic-container>
</template>
<script>
import {
  getMetaCommonList,
  getMetaCommonListExport,
  updateMetaCommon,
  getMetaCommonInfo,
  getPicListByIds,
} from "@/api/busiMode/metaCommon"
import { mapGetters } from "vuex"
import { getDictionary, getUrlAsyn } from "@/api/system/dictbiz"
import bankInfo from "./addEnterprise/bankInfo.vue"
import archives from "./archives.vue"
import { checkout } from "@/api/contract/index"

import { delCompany, joinInBlacklist } from "@/api/visitor/visitor"
import addEnterprise from "./addEnterprise"
import companyChangeList from "./companyChangeList"

import { managementOption } from "./option"
import { optionEnter } from "./optionEnter"
import { importCompanyBak, createUsers } from "@/api/user"

const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
export default {
  components: { addEnterprise, bankInfo, archives, companyChangeList },

  data() {
    return {
      data: [],
      loading: false,
      excelBox: false,
      detailDia: false,
      drawerDia: false,
      downLoading: false,
      enter_export: "wisdom_park.v_wp_company_export_stat",
      sum_model: "wisdom_park.v_wp_company_sum_emp_stat",
      companyChangeDia: false,
      viewPortHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      form: {
        name: "",
        uniform_social_credit_code: "",
      },
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      model: "wisdom_park.wp_company",
      checkinModel: "wisdom_park.wp_company_checkin",
      option: managementOption,
      currentRow: {},
      title: "",
      enterpriseTypes: [],
      firmSizeList: [],
      registeredList: [],
      companyTechnicalTypes: [],
      companyIsPublics: [],
      qualifications: [],
      params: {},
      sum: 0,
      doType: "",
      downId: "",
      search: {
        industry: "",
        name: "",
        uniform_social_credit_code: "",
      },
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: "请上传 .xls,.xlsx 标准格式文件",
            action: "/api/park-company/company/importCompanyBak",
          },
          {
            label: "模板下载",
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
    }
  },
  props: {
    enter: {
      type: String,
      default() {
        return ""
      },
    },
    choose: {
      type: Boolean,
      default() {
        return false
      },
    },
    blacklist: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  watch: {
    enter: {
      immediate: true,
      handler(val) {
        if (val == "enter") {
          this.option = JSON.parse(JSON.stringify(optionEnter))
        }
      },
    },
    choose: {
      immediate: true,
      handler(val) {
        // if (val == "enter") {
        this.option.selection = val
        this.option.refreshBtn = !val
        this.option.searchBtn = !val
        this.option.searchShow = !val
        this.option.searchShowBtn = !val
        this.option.menu = !val
        this.option.height = viewPortHeight * 0.6
        this.option = JSON.parse(JSON.stringify(this.option))
        // }
      },
    },
    blacklist: {
      immediate: true,
      handler(val) {
        if (val) {
          this.option.menuWidth = 100
        } else {
          this.option.menuWidth = 280
        }
        this.option = JSON.parse(JSON.stringify(this.option))
      },
    },
  },
  mounted() {},
  created() {
    this.getDicList()
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      }
    },
  },
  methods: {
    getBuildList() {
      let obj = {
        size: 1000,
        current: 1,
      }
      getMetaCommonList("wisdom_park.wp_building", obj).then((res) => {
        const data = res.data.data
        let dicData = []
        data.records.forEach((e) => {
          dicData.push({
            label: e.name,
            value: e.id,
          })
        })
        this.option.column[1].dicData = dicData
        this.$forceUpdate()
      })
    },
    handleTemplate() {
      // getPicListByIds(this.downId).then((res) => {
      //   if (res.data.data[0]) {
      //     console.log(res.data.data[0].link);
      //     window.open(`${res.data.data[0].link}`);
      //   }
      // });
      let url = `https://zhyy.qdgkcy.com/wisdom-park/common/企业信息和员工信息导入模版V3.0.xlsx
`
      window.open(url)
    },
    getMetaCommonListExport() {
      this.downLoading = true
      let obj = this.getSearch()
      let o = {
        current: obj.current,
        size: obj.size,
        wheres: obj.wheres,
      }
      getMetaCommonListExport(this.enter_export, o)
        .then((res) => {
          this.downLoading = false
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement("a")
          link.style.display = "none"
          link.href = url
          link.download = "企业档案" + ".xls"
          document.body.appendChild(link)
          link.click()
        })
        .catch((e) => {
          this.downLoading = false
        })
    },
    handleImport() {
      this.excelBox = true
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false
      this.onLoad()
      done()
    },
    checkout(row) {
      this.$confirm("确认将当前企业退出园区?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let r = {
          pk: "id",
          id: row.id,
        }
        getMetaCommonInfo(this.model, r).then((res) => {
          if (res.data.code == 200) {
            let obj = {}
            Object.keys(res.data.data).forEach((ele) => {
              obj[this.toHump(ele)] = res.data.data[ele]
            })
            obj["companyId"] = obj.id
            delete obj.id
            delete obj.type
            obj.approvalType = 1
            checkout(obj).then((res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              })
              this.onLoad()
            })
          }
        })
      })
    },
    toHump(str) {
      let nstr = str.replace(/(?:_)+([^_])/g, function ($0, $1) {
        return $1.toUpperCase()
      })
      nstr = nstr.replace(nstr[0], nstr[0].toLowerCase())
      return nstr
    },
    selectionChange(selection) {
      if (selection.length) {
        if (selection.length > 1) {
          const nowVal = selection.shift()
          this.$refs.crud.toggleRowSelection(nowVal, false)
        } else if (selection.length === 1) {
          this.$emit("selectionChange", selection)
        } else {
          this.$message.warning("只能选择一条数据进行操作!")
        }
      } else {
        this.$message.warning("请选择一条数据进行操作!")
      }
    },
    getDicList() {
      getDictionary({
        code: "firmSize",
      }).then((res) => {
        this.firmSizeList = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: item["dictKey"],
          }
        })
      })
      getDictionary({
        code: "registered_capital_currency",
      }).then((res) => {
        this.registeredList = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: item["dictKey"],
          }
        })
      })

      getDictionary({
        code: "companyIsPublic",
      }).then((res) => {
        this.companyIsPublics = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: Number(item["dictKey"]),
          }
        })
      })
      getUrlAsyn("ImportTemplate").then((res) => {
        if (res.data.data[0].dictKey) {
          this.downId = res.data.data[0].dictKey
        }
      })
      getDictionary({
        code: "companyTechnicalType",
      }).then((res) => {
        this.companyTechnicalTypes = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: Number(item["dictKey"]),
          }
        })
      })
      getDictionary({
        code: "enterpriseType",
      }).then((res) => {
        this.enterpriseTypes = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: item["dictKey"],
          }
        })
      })
      getDictionary({
        code: "qualification",
      }).then((res) => {
        this.qualifications = (res.data.data || []).map((item) => {
          return {
            name: item["dictValue"],
            value: item["dictKey"],
          }
        })
      })
    },
    openDislog(type, row) {
      this.doType = type
      this.currentRow = {}
      if (type == "detail") {
        this.detailDia = true
        this.$nextTick(() => (this.currentRow = row))
        this.title = "详情"
      }
      if (type == "edit") {
        this.detailDia = true
        this.$nextTick(() => (this.currentRow = row))
        this.title = "编辑"
      }
      if (type == "bg") {
        this.detailDia = true
        this.$nextTick(() => (this.currentRow = row))
        this.title = "变更"
      }
      if (type == "add") {
        this.detailDia = true
        this.title = "新增"
        this.$nextTick(() => (this.currentRow = {}))
      }
      if (type == "qyda") {
        this.title = "企业档案"
        this.drawerDia = true
        this.$nextTick(() => (this.currentRow = row))
      }
      if (type == "khxx") {
        this.title = "开户信息"
        this.detailDia = true
        this.$nextTick(() => (this.currentRow = row))
      }
      if (type == "bgjl") {
        this.companyChangeDia = true
        this.currentRow = row
        // this.$nextTick(() => (this.currentRow = row));
      }
    },
    refreshChange() {
      this.onLoad(this.page)
    },
    close() {
      this.drawerDia = false
      this.detailDia = false
      this.currentRow = {}
      this.refreshChange()
    },
    getSearch() {
      let wheres = []
      let params = this.params
      for (let key in params) {
        // if (params[key]) {
        if (key == "name") {
          wheres.push({ field: key, op: "LIKE", value: params[key] })
        } else {
          wheres.push({ field: key, value: params[key] })
        }
        // }
      }
      let body = {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
        orders: [{ field: "create_time", sort: "DESC" }],
      }
      if (this.blacklist) {
        body["wheres"].push({
          field: "black_list_status",
          value: 1,
        })
      }
      return body
    },
    searchChange(params, done) {
      this.params = params
      let o = this.getSearch(params)
      this.getMetaCommonList(o)
      this.getMetaCommonSum(o)
      done()
    },
    searchReset() {
      this.onLoad()
    },
    onLoad() {
      let wheres = []
      for (let key in this.params) {
        if ("" + this.params[key] && key != "isContract") {
          if (key == "name") {
            wheres.push({ field: key, op: "LIKE", value: this.params[key] })
          } else {
            wheres.push({ field: key, value: this.params[key] })
          }
        }
      }
      this.loading = true
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: [{ field: "create_time", sort: "DESC" }],
      }

      if (this.form.name) {
        body["wheres"].push({
          field: "name",
          op: "LIKE",
          value: this.form.name,
        })
      }
      if (this.blacklist) {
        body["wheres"].push({
          field: "black_list_status",
          value: 1,
        })
      }
      this.getMetaCommonList(body)
      this.getMetaCommonSum(body)
    },
    getMetaCommonSum(body) {
      getMetaCommonList(this.sum_model, body).then((res) => {
        this.sum = 0
        res.data.data.records.forEach((e) => {
          this.sum = this.sum + Number(e.building_emp_count)
        })
      })
    },

    getMetaCommonList(body) {
      getMetaCommonList("wisdom_park.v_wp_company_building_code_stat", body).then((res) => {
        const data = res.data.data
        this.page.total = data.total
        data.records.forEach((e) => {
          if (e.type) {
            this.enterpriseTypes.forEach((type) => {
              if (e.type == type.value) {
                e.type = type.name
              }
            })
          }
          if (e.qualification) {
            this.qualifications.forEach((q) => {
              if (e.qualification == q.value) {
                e.qualification = q.name
              }
            })
          }
          if (e.registered_capital_currency) {
            this.registeredList.forEach((q) => {
              if (e.registered_capital_currency == q.value) {
                e.registered_capital_currency = q.name
              }
            })
          }
          if (e.firm_size) {
            this.firmSizeList.forEach((q) => {
              if (e.firm_size == q.value) {
                e.firm_size = q.name
              }
            })
          }
          if (e.technical_type) {
            let types = [e.technical_type]
            if (e.technical_type.split(",")) {
              types = e.technical_type.split(",")
            }
            let name = ""
            types.forEach((ele) => {
              this.companyTechnicalTypes.forEach((q) => {
                if (ele == q.value) {
                  if (name) {
                    name = name + "," + q.name
                  } else {
                    name = name + q.name
                  }
                }
              })
            })
            e.technical_type = name
          }
          if (e.is_public) {
            this.companyIsPublics.forEach((q) => {
              if (e.is_public == q.value) {
                e.is_public = q.name
              }
            })
          }
        })
        this.getBuildList()
        this.data = data.records
        this.loading = false
      })
    },
    removeBlackList(row) {
      this.$confirm("确认将当前企业从黑名单中移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let r = {
            pk: "id",
            id: row.id,
          }
          getMetaCommonInfo(this.model, r).then((res) => {
            if (res.data.code == 200) {
              let form = res.data.data
              form.black_list_status = 0
              let o = {
                update: form,
                primaryKeyValue: form.id,
                primaryKey: "id",
              }
              updateMetaCommon(this.model, o).then((res) => {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                })
                this.onLoad(this.page)
              })
            }
          })
        })
        .catch(() => {})
    },
    jrhmd(row) {
      this.$confirm("确认将当前企业加入黑名单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let r = {
          pk: "id",
          id: row.id,
        }
        getMetaCommonInfo(this.model, r).then((res) => {
          if (res.data.code == 200) {
            let form = res.data.data
            form.black_list_status = 1
            let o = {
              update: form,
              primaryKeyValue: form.id,
              primaryKey: "id",
            }

            joinInBlacklist({ id: form.id }).then((res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              })
              this.onLoad(this.page)
            })
          }
        })
      })
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCompany({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.onLoad(this.page)
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0px;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
.enterDetail /deep/ .el-dialog__body {
  padding: 0px;
}
/deep/ .el-drawer__body {
  padding-top: 0px;
  overflow-x: hidden !important;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 0px;
  padding-bottom: 15px;
}

/deep/.el-table--border td:first-child .cell {
  padding-left: 0px;
}
.sum_clazz {
  width: 200px;
  position: absolute;
  bottom: 70px;
  left: 15px;
  font-size: 14px;
}
</style>
