const prefix = '/api/park-contract/';
const subPrefix = '/api/park-contract/subContract/'

export default {
  save: prefix + 'contract/save',
  update: prefix + 'contract/update',
  updateAttachments: prefix + 'contract/updateAttachments',
  remove: prefix + 'contract/remove',
  calculate: prefix + 'contract/calculate',
  list: prefix + 'contract/page',
  formalPage: prefix + 'contract/formal-page',
  exportFormalContract: prefix + 'contract/export-formal-contract',
  detail: prefix + 'contract/detail',
  queryCollectionPlan: prefix + 'contract/queryCollectionPlan',
  cancelRent: prefix + 'contract/cancelRent',
  cancellation: prefix + 'contract/cancellation',
  close: prefix + 'contract/close',
  countDepositAmount: prefix + 'contract/count-deposit-amount',
  supplementarySave: prefix + 'contract-supplementary/save',
  supplementaryUpdate: prefix + 'contract-supplementary/update',
  supplementaryDetail: prefix + 'contract-supplementary/detail',
  supplementaryCollectionPlan: prefix + 'contract-supplementary/queryCollectionPlan',
  supplementaryCalculate: prefix + 'contract-supplementary/calculate',
  exportContract: prefix + 'contract/export-contract',
  delete: prefix + "contract/customRemove",
  subContract: {
    list: subPrefix + 'list',
    save: subPrefix + 'save',
    update: subPrefix + 'update',
    remove: subPrefix + 'remove',
    delete: '/api/park-contract/contract-supplementary/customRemove',
    detail: subPrefix + 'detail',
    subject: subPrefix + 'getContractSubject'
  }
}